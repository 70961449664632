import React, { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuth } from 'modules/auth';
import { connectToSignalR, disconnectFromSignalR } from 'modules/service/signalr';

interface IProps {
  children: ReactElement;
}

const AuthGuard: React.FC<IProps> = ({ children }) => {
  const isLoggedIn = useAuth((state) => !!state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('login', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (isLoggedIn) {
      connectToSignalR();
      return () => {
        disconnectFromSignalR();
      };
    }
  }, [isLoggedIn]);

  return children;
};

export default AuthGuard;
