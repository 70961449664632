import { CodeSandboxOutlined, ToolOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

const tools: NavItemType = {
  id: 'tools',
  title: <FormattedMessage id="tools" />,
  type: 'group',
  permission: PERMISSIONS.DELIVERIES.READ,
  children: [
    {
      id: 'sandbox',
      title: <FormattedMessage id="sandbox" />,
      type: 'collapse',
      icon: CodeSandboxOutlined,
      permission: PERMISSIONS.DELIVERIES.READ,
      children: [
        {
          id: 'sandbox-batching',
          title: <FormattedMessage id="batching" />,
          type: 'item',
          url: '/tools/sandbox/batching'
        }
      ]
    },
    {
      id: 'operations',
      title: <FormattedMessage id="operations" />,
      type: 'collapse',
      icon: ToolOutlined,
      permission: PERMISSIONS.DELIVERIES.READ,
      children: [
        {
          id: 'warehouseLabel',
          title: <FormattedMessage id="warehouse-label" />,
          type: 'item',
          url: '/tools/operations/warehouseLabel'
        }
      ]
    }
  ]
};

export default tools;
