import { NavItemType } from 'types/menu';

import admin from './admin';
import content from './content';
import fleet from './fleet';
import main from './main';
import marketing from './marketing';
import reports from './reports';
import tools from './tools';
import warehouse from './warehouse';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [main, fleet, marketing, warehouse, content, tools, admin, reports]
};

export default menuItems;
