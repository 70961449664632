import { PropsWithChildren } from 'react';

import { useLoadScript } from '@react-google-maps/api';

import Loader from 'components/Loader';
import useTrackFirebase from 'hooks/useTrackFirebase';

const AppInitialiser: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { isLoading: isFirebaseLoading } = useTrackFirebase();
  const { isLoaded: isMapsLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string });

  if (isFirebaseLoading || !isMapsLoaded) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default AppInitialiser;
