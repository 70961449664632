// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return (
    <svg width="44" height="35" id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.47 284.21">
      <g id="Layer_1-2">
        <g>
          <path
            fill="#eb5483"
            d="M86.96,123.21l-25.51,161.01H0L25.51,123.21H4.2l8.09-50.56h21l1.71-10.73C39.2,35.47,56.31,0,112,0c16.65,0,29.56,3.89,32.98,5.6l-14,48.22c-2.33-1.09-9.8-3.27-19.13-3.27-8.25,0-16.49,3.89-18.36,15.4l-1.09,6.69h36.71l-7.93,50.56h-34.22Z"
          />
          <polygon fill="#eb5483" points="187.61 221.99 123.83 221.99 147.47 72.65 211.25 72.65 187.61 221.99" />
          <path
            fill="#eb5483"
            d="M213.26,184.34l64.09-60.51h-53.36l8.09-51.18h125.38l-5.91,37.18-64.87,60.98h59.74l-8.09,51.18H207.35l5.91-37.65Z"
          />
          <path
            fill="#e95482"
            d="M210.38,32.32c.17,16.72-11.66,28.28-29.27,28.57-17.09,.28-29.62-11.54-29.8-28.14-.19-16.59,12-28.47,29.3-28.56,17.28-.09,29.6,11.55,29.77,28.13Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
