import { HubConnectionState } from '@microsoft/signalr';

import { IStatusColors } from 'components/Status';

export const SignalRStateDetails: { [key in HubConnectionState]: { label: string; color: IStatusColors } } = {
  [HubConnectionState.Disconnected]: { label: 'Live Updates Off', color: 'error' },
  [HubConnectionState.Connecting]: { label: 'Connecting...', color: 'warning' },
  [HubConnectionState.Connected]: { label: 'Live Updates On', color: 'success' },
  [HubConnectionState.Disconnecting]: { label: 'Disconnecting...', color: 'error' },
  [HubConnectionState.Reconnecting]: { label: 'Reconnecting...', color: 'warning' }
};
