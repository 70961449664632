import { lazy } from 'react';

import { Outlet, Route } from 'react-router';

import Loadable from 'routes/components/Loadable';
import GuestGuard from 'routes/components/route-guard/GuestGuard';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = (
  <Route path="/login" element={<GuestGuard children={<Outlet />} />}>
    <Route index element={<AuthLogin />} />
  </Route>
);

export default LoginRoutes;
