import { ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuth } from 'modules/auth';
import config from 'utils/config';

interface IProps {
  children: ReactElement;
}

const GuestGuard: React.FC<IProps> = ({ children }) => {
  const isLoggedIn = useAuth((state) => !!state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

export default GuestGuard;
