import { Routes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const Router = () => (
  <Routes>
    {LoginRoutes}
    {MainRoutes}
  </Routes>
);

export default Router;
