import { Container, Box, Paper, Typography, Stack, Divider, Button } from '@mui/material';
import React from 'react';

import { useErrorBoundary } from 'react-error-boundary';

import Logo from 'components/logo';

const FallbackRenderer: React.FC<{ error: Error }> = (error) => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Container sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper elevation={4} sx={{ borderRadius: 2, width: '600px' }}>
        <Box p={3}>
          <Stack direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Logo />
            <Box flex={1} />
            <Typography variant="h4">OOPS!</Typography>
          </Stack>
        </Box>
        <Divider />
        <Box p={4}>
          <Stack spacing={2}>
            <Typography fontSize={17}>Message: {error.error.message}</Typography>
            <Button variant="outlined" onClick={resetBoundary}>
              RELOAD THE PAGE
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Container>
  );
};

export default FallbackRenderer;
