import { useEffect, useState } from 'react';

import firebase from 'firebase/compat/app';

import Auth from 'modules/auth';

const useTrackFirebase = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async () => {
        try {
          await Auth.verifyUser();
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }),
    []
  );
  return { isLoading };
};

export default useTrackFirebase;
