import { useEffect } from 'react';

import { HubConnectionBuilder, HubConnection, HubConnectionState } from '@microsoft/signalr';
import camelcaseKeys from 'camelcase-keys';
import firebase from 'firebase/compat/app';

import { setLastUpdated, setState } from './store';
import { ISignalRData, SignalRMethodNames } from './types';

let SignalRConnection: HubConnection;

export const connectToSignalR = () => {
  SignalRConnection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNALR_URL as string, {
      headers: {
        'x-functions-key': process.env.REACT_APP_SIGNALR_KEY as string,

        'x-ms-client-principal-id': ''
      }
    })
    .withAutomaticReconnect()
    .build();

  setState(HubConnectionState.Connecting);
  SignalRConnection.start().finally(() => setState(SignalRConnection.state));
  SignalRConnection.onclose(() => setState(SignalRConnection.state));
  SignalRConnection.onreconnected(() => setState(SignalRConnection.state));
  SignalRConnection.onreconnecting(() => setState(SignalRConnection.state));
};

export const disconnectFromSignalR = () => SignalRConnection.stop();

interface IProps<T extends SignalRMethodNames> {
  methodName: T;
  method: (...args: [ISignalRData[T]]) => void;
}

export const useSignalREffect = <T extends SignalRMethodNames>({ methodName, method }: IProps<T>): void => {
  useEffect(() => {
    const methodWithCamelcase = (data: any) => {
      setLastUpdated(new Date());
      method(camelcaseKeys(data, { deep: true }));
    };
    SignalRConnection.on(methodName, methodWithCamelcase);
    return () => {
      SignalRConnection.off(methodName, methodWithCamelcase);
    };
  }, [method, methodName]);
};

export * from './types';
export * from './consts';
export { useSignalRState } from './store';
