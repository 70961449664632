import { ShoppingOutlined, UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const main: NavItemType = {
  id: 'main',
  type: 'group',
  children: [
    {
      id: 'orders',
      title: <FormattedMessage id="orders" />,
      type: 'collapse',
      url: '/orders',
      icon: ShoppingOutlined,
      permission: PERMISSIONS.ORDERS.READ,
      children: [
        {
          id: 'orders-today',
          title: <FormattedMessage id="today" />,
          type: 'item',
          url: '/orders/today'
        },
        {
          id: 'orders-history',
          title: <FormattedMessage id="history" />,
          type: 'item',
          url: '/orders/history'
        },
        {
          id: 'orders-status-board',
          title: <FormattedMessage id="statusboard" />,
          type: 'item',
          url: '/orders/status-board'
        }
      ]
    },
    {
      id: 'customers',
      title: <FormattedMessage id="customers" />,
      type: 'collapse',
      url: '/customers',
      icon: UserOutlined,
      permission: PERMISSIONS.CUSTOMERS.READ,
      children: [
        {
          id: 'customers-list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/customers/list'
        },
        {
          id: 'customers-reviews',
          title: <FormattedMessage id="reviews" />,
          type: 'item',
          url: '/customers/reviews'
        }
      ]
    }
  ]
};

export default main;
