import { ApartmentOutlined, ScanOutlined, ShopOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const warehouse: NavItemType = {
  id: 'warehouse',
  title: <FormattedMessage id="warehouses" />,
  type: 'group',
  permission: PERMISSIONS.WAREHOUSES.READ | PERMISSIONS.AREAS.READ | PERMISSIONS.PICKERS.READ,
  children: [
    {
      id: 'warehouses',
      title: <FormattedMessage id="warehouses" />,
      type: 'item',
      url: '/warehouses',
      icon: ShopOutlined,
      permission: PERMISSIONS.WAREHOUSES.READ
    },
    {
      id: 'areas',
      title: <FormattedMessage id="areas" />,
      type: 'item',
      url: '/warehouses/areas',
      icon: ApartmentOutlined,
      permission: PERMISSIONS.AREAS.READ
    },
    {
      id: 'pickers',
      title: <FormattedMessage id="pickers" />,
      type: 'item',
      url: '/warehouses/pickers',
      icon: ScanOutlined,
      permission: PERMISSIONS.PICKERS.READ
    }
  ]
};

export default warehouse;
