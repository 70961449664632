import axios, { AxiosResponse } from 'axios';
import { AxiosRequestConfig } from 'axios';

import { requestBeforeInterceptor, requestErrorInterceptor, responseErrorInterceptor, responseSuccessInterceptor } from './interceptor';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers['x-api-key'] = process.env.REACT_APP_API_KEY as string;
axios.interceptors.request.use(requestBeforeInterceptor, requestErrorInterceptor);
axios.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

class Fetcher {
  static Post = async <DataType, ResponseType>(endpoint: string, data?: DataType, config?: AxiosRequestConfig): Promise<ResponseType> => {
    const response = await axios.post<ResponseType>(endpoint, data, config);
    return response.data;
  };

  static Get = async <ResponseType, D = any>(endpoint: string, params?: D, config?: AxiosRequestConfig): Promise<ResponseType> => {
    const response = await axios.get<ResponseType, AxiosResponse<ResponseType>, D>(endpoint, { ...config, params });
    return response.data;
  };

  static Delete = async <ResponseType, D = any>(endpoint: string, params?: D, config?: AxiosRequestConfig): Promise<ResponseType> => {
    const response = await axios.delete<ResponseType, AxiosResponse<ResponseType>, D>(endpoint, { ...config, params });
    return response.data;
  };

  static Patch = async <DataType, ResponseType>(endpoint: string, data?: DataType, config?: AxiosRequestConfig): Promise<ResponseType> => {
    const response = await axios.patch<ResponseType>(endpoint, data, config);
    return response.data;
  };
}

export default Fetcher;
