import { Stack, Typography, useTheme } from '@mui/material';

import LogoIcon from './LogoIcon';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();
  return (
    <>
      <LogoIcon />
      <Stack>
        <Typography
          fontSize="1.4rem"
          marginLeft={2}
          fontWeight={900}
          color={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black}
        >
          Dashboard
        </Typography>
      </Stack>
    </>
  );
};

export default LogoMain;
