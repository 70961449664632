import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

import { StoreHookType } from 'types/common';
import { MenuStoreProps } from 'types/menu';

const MenuVanillaStore = createStore<MenuStoreProps>((set, get) => ({
  openItem: ['dashboard'],
  openComponent: 'buttons',
  drawerOpen: false,
  componentDrawerOpen: true,
  setActiveItem: (openItem) => set({ openItem }),
  setDrawerOpen: (drawerOpen) => set({ drawerOpen })
}));

const useMenu: StoreHookType<MenuStoreProps> = (selector, equalityFn?) => useStore(MenuVanillaStore, selector, equalityFn);

export const { setActiveItem, setDrawerOpen } = MenuVanillaStore.getState();
export default useMenu;
