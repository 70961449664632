import { CarOutlined, TeamOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const fleet: NavItemType = {
  id: 'fleet',
  title: <FormattedMessage id="fleet" />,
  type: 'group',
  permission: PERMISSIONS.DELIVERIES.READ | PERMISSIONS.DRIVERS.READ,
  children: [
    {
      id: 'deliveries',
      title: <FormattedMessage id="deliveries" />,
      type: 'collapse',
      icon: CarOutlined,
      permission: PERMISSIONS.DELIVERIES.READ,
      children: [
        {
          id: 'deliveries-active',
          title: <FormattedMessage id="active" />,
          type: 'item',
          url: '/fleet/deliveries/active'
        },
        {
          id: 'deliveries-history',
          title: <FormattedMessage id="history" />,
          type: 'item',
          url: '/fleet/deliveries/history'
        },
        {
          id: 'deliveries-batching',
          title: <FormattedMessage id="batching" />,
          type: 'item',
          url: '/fleet/deliveries/batching'
        }
      ]
    },
    {
      id: 'drivers',
      title: <FormattedMessage id="drivers" />,
      type: 'collapse',
      icon: TeamOutlined,
      permission: PERMISSIONS.DRIVERS.READ,
      children: [
        {
          id: 'drivers-directory',
          title: <FormattedMessage id="directory" />,
          type: 'item',
          url: '/fleet/drivers'
        },
        {
          id: 'drivers-shifts',
          title: <FormattedMessage id="shifts" />,
          type: 'item',
          url: '/fleet/drivers/shifts'
        },
        {
          id: 'drivers-schedule',
          title: <FormattedMessage id="schedule" />,
          type: 'item',
          url: '/fleet/drivers/schedule'
        }
      ]
    }
  ]
};

export default fleet;
