export interface IWarehouseListItem {
  id: string;
  name: string;
  address: string;
  schedule: number[];
  status: WarehouseStatuses;
  serviceHours: ServiceHours;
  etaBufferTime: number;
  deliverooSiteStatus: DeliverooSiteStatus | null;
  cariSiteStatus: CariSiteStatus | null;
}

export enum WarehouseStatuses {
  Active = 0,
  Busy = 1,
  Inactive = 2
}

export enum DeliverooSiteStatus {
  Open = 0,
  Closed = 1,
  ReadyToOpen = 2
}

export enum CariSiteStatus {
  Open = 0,
  Closed = 1
}

export interface ServiceHours {
  startTime: string;
  endTime: string;
}

export interface IWarehouseDetails {
  id: string;
  shopifyId: string;
  name: string;
  serviceHoursStartTime: string;
  serviceHoursEndTime: string;
  serviceDays: number[];
  status: WarehouseStatuses;
  busyMessageEnglish: string;
  busyMessageArabic: string;
  etaBufferTime: number;
}

export interface IStoreDeliverooDetails {
  id: string;
  deliverooSiteId: string | null;
  deliverooSiteStatus: DeliverooSiteStatus | null;
  deliverooServiceHoursStartTime: string | null;
  deliverooServiceHoursEndTime: string | null;
}

export interface IDeliverooSiteSettings {
  id: string;
  deliverooSiteId: string | null;
}

export interface IStoreCariDetails {
  id: string;
  status: boolean;
}
