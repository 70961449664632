export const PERMISSIONS = {
  NONE: BigInt(0),
  SOME: BigInt(1) << BigInt(0),
  EMPLOYEES: {
    READ: BigInt(1) << BigInt(1),
    UPDATE: BigInt(1) << BigInt(2),
    MANAGE: BigInt(1) << BigInt(3)
  },
  ORDERS: {
    READ: BigInt(1) << BigInt(4),
    UPDATE: BigInt(1) << BigInt(5),
    MANAGE: BigInt(1) << BigInt(6)
  },
  DELIVERIES: {
    READ: BigInt(1) << BigInt(7),
    UPDATE: BigInt(1) << BigInt(8),
    MANAGE: BigInt(1) << BigInt(9)
  },
  DRIVERS: {
    READ: BigInt(1) << BigInt(10),
    UPDATE: BigInt(1) << BigInt(11),
    MANAGE: BigInt(1) << BigInt(12)
  },
  WAREHOUSES: {
    READ: BigInt(1) << BigInt(13),
    UPDATE: BigInt(1) << BigInt(14),
    MANAGE: BigInt(1) << BigInt(15)
  },
  AREAS: {
    READ: BigInt(1) << BigInt(16),
    UPDATE: BigInt(1) << BigInt(17),
    MANAGE: BigInt(1) << BigInt(18)
  },
  BANNERS: {
    READ: BigInt(1) << BigInt(19),
    UPDATE: BigInt(1) << BigInt(20),
    MANAGE: BigInt(1) << BigInt(21)
  },
  CATEGORIES: {
    READ: BigInt(1) << BigInt(22),
    UPDATE: BigInt(1) << BigInt(23),
    MANAGE: BigInt(1) << BigInt(24)
  },
  PRODUCTS: {
    READ: BigInt(1) << BigInt(25),
    UPDATE: BigInt(1) << BigInt(26),
    MANAGE: BigInt(1) << BigInt(27)
  },
  CUSTOMERS: {
    READ: BigInt(1) << BigInt(28),
    UPDATE: BigInt(1) << BigInt(29),
    MANAGE: BigInt(1) << BigInt(30)
  },
  PICKERS: {
    READ: BigInt(1) << BigInt(31),
    UPDATE: BigInt(1) << BigInt(32),
    MANAGE: BigInt(1) << BigInt(33)
  },
  DISCOUNTS: {
    READ: BigInt(1) << BigInt(34),
    UPDATE: BigInt(1) << BigInt(35),
    MANAGE: BigInt(1) << BigInt(36)
  },
  COLLECTIONS: {
    READ: BigInt(1) << BigInt(37),
    UPDATE: BigInt(1) << BigInt(38),
    MANAGE: BigInt(1) << BigInt(39)
  },
  SHOPIFY: {
    SYNC: BigInt(1) << BigInt(40)
  },
  DELIVERY_SETTINGS: {
    MANAGE: BigInt(1) << BigInt(41)
  },
  SEGMENTS: {
    READ: BigInt(1) << BigInt(42),
    UPDATE: BigInt(1) << BigInt(43),
    MANAGE: BigInt(1) << BigInt(44)
  },
  LARGE_ORDER_ETA_SETTINGS: {
    MANAGE: BigInt(1) << BigInt(45)
  },
  DISCOUNTS_SETTINGS: {
    MANAGE: BigInt(1) << BigInt(46)
  },
  PAYMENTS_SETTINGS: {
    MANAGE: BigInt(1) << BigInt(47)
  },

  REPORTS: {
    READ: BigInt(1) << BigInt(48)
  },
  ALL: BigInt('18446744073709551615')
};
