import React from 'react';

import { Outlet } from 'react-router';

export const withOutletForModal = (Component: React.ComponentType) => (
  <>
    <Component />
    <Outlet />
  </>
);
