import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

import { StoreHookType } from 'types/common';
import { CustomizationProps } from 'types/config';
import config from 'utils/config';

const ConfigVanillaStore = createStore<CustomizationProps>(() => config);

const useConfig: StoreHookType<CustomizationProps> = (selector, equalityFn?) => useStore(ConfigVanillaStore, selector, equalityFn);

export default useConfig;
