import { alpha, createTheme } from '@mui/material/styles';
import { useMemo } from 'react';

import { shallow } from 'zustand/shallow';

import useConfig from 'store/config';

import { defaultColorBuilder } from './colors';
import componentsOverride from './overrides';
import CustomShadows from './shadows';
import Typography from './typography';

const useThemeBuilder = () => {
  const { themeDirection, mode, fontFamily } = useConfig(
    (state) => ({ themeDirection: state.themeDirection, mode: state.mode, fontFamily: state.fontFamily }),
    shallow
  );

  // GET THEME COLORS
  const themeColors = useMemo(() => defaultColorBuilder(mode), [mode]);

  // GENERATE PALETTE FOR THEME
  const palette = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          common: {
            black: '#000',
            white: '#fff'
          },
          ...themeColors,
          text: {
            primary: mode === 'dark' ? alpha(themeColors.grey[900]!, 0.87) : themeColors.grey[700],
            secondary: mode === 'dark' ? alpha(themeColors.grey[900]!, 0.45) : themeColors.grey[500],
            disabled: mode === 'dark' ? alpha(themeColors.grey[900]!, 0.1) : themeColors.grey[400]
          },
          action: {
            disabled: themeColors.grey[300]
          },
          divider: mode === 'dark' ? alpha(themeColors.grey[900]!, 0.05) : themeColors.grey[200],
          background: {
            paper: mode === 'dark' ? themeColors.grey[100] : themeColors.grey[0],
            default: themeColors.grey.A50
          }
        }
      }).palette,
    [mode, themeColors]
  );

  // GET THEME TYPOGRAPHY (THERE'S A CUSTOM ONE FROM MANTIS TEMPLATE)
  const themeTypography = useMemo(() => Typography(fontFamily), [fontFamily]);

  // GET THEME CUSTOM SHADOWS (THERE'S A CUSTOM ONE FROM MANTIS TEMPLATE)
  const themeCustomShadows = useMemo(() => CustomShadows(palette), [palette]);

  // BULD THEME OPTIONS WITH USING ALL THE ABOVE VALUES (PALETTE, TYPOGRAPHY, CUSTOM SHADOWS)
  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440
        }
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette,
      customShadows: themeCustomShadows,
      typography: themeTypography
    }),
    [palette, themeCustomShadows, themeDirection, themeTypography]
  );

  // CREATE THEME
  const theme = useMemo(() => {
    const mTheme = createTheme(themeOptions);
    mTheme.components = componentsOverride(mTheme);
    return mTheme;
  }, [themeOptions]);

  return theme;
};

export default useThemeBuilder;
