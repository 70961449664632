import { QueryClient, QueryKey, SetDataOptions } from '@tanstack/react-query';

import { setupWHQueryDefaults } from 'modules/warehouse/wh-query-defaults';

export const queryClient = new QueryClient();

export const setQueryData = <T>(queryKey: QueryKey, updater: (old: T) => T, options?: SetDataOptions | undefined): T | undefined =>
  queryClient.setQueryData<T | undefined>(queryKey, (old) => (old ? updater(old) : undefined), options);

// Setup query defaults
setupWHQueryDefaults(queryClient);
