import { PERMISSIONS } from './consts';

module Permissions {
  export const check = (requiredPermission: bigint, userPermission: bigint) => {
    if (requiredPermission === PERMISSIONS.NONE) return true;
    return Boolean(userPermission & requiredPermission);
  };
}

export default Permissions;
export * from './components';
export * from './consts';
export * from './hooks';
