import { SettingOutlined, SmileOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const admin: NavItemType = {
  id: 'admin',
  title: <FormattedMessage id="admin" />,
  type: 'group',
  permission:
    PERMISSIONS.EMPLOYEES.READ |
    PERMISSIONS.SHOPIFY.SYNC |
    PERMISSIONS.DELIVERY_SETTINGS.MANAGE |
    PERMISSIONS.LARGE_ORDER_ETA_SETTINGS.MANAGE |
    PERMISSIONS.DISCOUNTS_SETTINGS.MANAGE |
    PERMISSIONS.PAYMENTS_SETTINGS.MANAGE,
  children: [
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'collapse',
      icon: SettingOutlined,
      permission:
        PERMISSIONS.SHOPIFY.SYNC |
        PERMISSIONS.DELIVERY_SETTINGS.MANAGE |
        PERMISSIONS.LARGE_ORDER_ETA_SETTINGS.MANAGE |
        PERMISSIONS.DISCOUNTS_SETTINGS.MANAGE |
        PERMISSIONS.PAYMENTS_SETTINGS.MANAGE,
      children: [
        {
          id: 'shopify-sync',
          title: <FormattedMessage id="shopify-sync" />,
          type: 'item',
          url: '/admin/settings/shopify-sync',
          permission: PERMISSIONS.SHOPIFY.SYNC
        },
        {
          id: 'delivery',
          title: <FormattedMessage id="delivery" />,
          type: 'item',
          url: '/admin/settings/delivery',
          permission: PERMISSIONS.DELIVERY_SETTINGS.MANAGE
        },
        {
          id: 'payment-settings',
          title: <FormattedMessage id="payments" />,
          type: 'item',
          url: '/admin/settings/payments',
          permission: PERMISSIONS.PAYMENTS_SETTINGS.MANAGE
        },
        {
          id: 'largeOrderEta',
          title: <FormattedMessage id="largeOrderEta" />,
          type: 'item',
          url: '/admin/settings/largeOrderEta',
          permission: PERMISSIONS.LARGE_ORDER_ETA_SETTINGS.MANAGE
        },
        {
          id: 'deliverooSettings',
          title: <FormattedMessage id="deliverooSettings" />,
          type: 'item',
          url: '/admin/settings/deliveroo',
          permission: PERMISSIONS.WAREHOUSES.MANAGE
        },
        {
          id: 'discountsSettings',
          title: <FormattedMessage id="discountsSettings" />,
          type: 'item',
          url: '/admin/settings/discountsSettings',
          permission: PERMISSIONS.DISCOUNTS_SETTINGS.MANAGE
        }
      ]
    },
    {
      id: 'employees',
      title: <FormattedMessage id="employees" />,
      type: 'item',
      url: '/admin/employees',
      icon: SmileOutlined,
      permission: PERMISSIONS.EMPLOYEES.READ
    }
  ]
};

export default admin;
