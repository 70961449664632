import { QueryClientProvider } from '@tanstack/react-query';

import Snackbar from 'components/@extended/Snackbar';
import AppInitialiser from 'components/AppInitialiser';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import { queryClient } from 'utils/react-query';

import 'utils/firebase-init';

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeCustomization>
        {/* <RTLLayout> */}
        <Locales>
          <ScrollTop>
            <AppInitialiser>
              <Routes />
              <Snackbar />
            </AppInitialiser>
          </ScrollTop>
        </Locales>
        {/* </RTLLayout> */}
      </ThemeCustomization>
    </QueryClientProvider>
  );
};

export default App;
