import { QueryClient } from '@tanstack/react-query';

import { EP_WAREHOUSES_LIST } from 'modules/endpoints';
import Warehouses from 'modules/warehouse';

export const setupWHQueryDefaults = (queryClient: QueryClient) => {
  queryClient.setQueryDefaults([EP_WAREHOUSES_LIST], { queryFn: Warehouses.list, staleTime: Infinity });
  queryClient.setQueryDefaults([EP_WAREHOUSES_LIST + 'cari_status'], {
    queryFn: () => Warehouses.listWithCariStatus(true),
    staleTime: Infinity
  });
};
