import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import firebase from 'firebase/compat/app';

import Snackbar from 'components/@extended/Snackbar';

import { AxiosErrorWithMeta, IAPIModelValidationError } from './types';

axiosRetry(axios, {
  retries: 1,
  retryCondition: async (error) => {
    const status = error.response?.status;
    if (status !== 401 && status !== 403) return false;

    try {
      return Boolean(await firebase.auth().currentUser?.getIdToken(true));
    } catch (error: any) {
      return false;
    }
  }
});

export const requestBeforeInterceptor = async (req: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const headers = req.headers || {};
  // Bearer Header
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    console.log(error);
  }

  req.headers = headers;
  return req;
};

export const requestErrorInterceptor = (err: AxiosError): AxiosError => {
  return err;
};

export const responseSuccessInterceptor = (res: AxiosResponse): AxiosResponse => {
  return res;
};

export const responseErrorInterceptor = (err: AxiosErrorWithMeta<IAPIModelValidationError>): Promise<never> => {
  switch (err.response?.status) {
    case 400:
      if (err.response.data) {
        const data: IAPIModelValidationError = err.response.data;
        Snackbar.show(Object.values(data.errors)[0][0] || data.title, 'error');
      }
      break;
  }

  return Promise.reject(err);
};
