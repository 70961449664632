import { Theme } from '@mui/material/styles';

import { generate } from '@ant-design/colors';

import { ColorProps } from 'types/extended';

interface IRGB {
  r: number;
  g: number;
  b: number;
}

const generateColorsFromString = (str: string) => generate(generateHexColorFromString(str));

const generateHexColorFromString = (str: string): string => {
  const hash = str.split('').reduce((prev, curr) => curr.charCodeAt(0) + ((prev << 5) - prev), 0);
  const color = '#' + ((hash & 0xffffff) + 0x1000000).toString(16).substr(1, 6);
  return adjustLuminance(color);
};

const hexToRgb = (hex: string) => {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);
  return { r, g, b };
};

const rgbToHex = (rgb: IRGB) => {
  let r = rgb.r.toString(16).padStart(2, '0');
  let g = rgb.g.toString(16).padStart(2, '0');
  let b = rgb.b.toString(16).padStart(2, '0');
  return `#${r}${g}${b}`;
};

const getLuminance = ({ r, g, b }: IRGB) => 0.2126 * r + 0.7152 * g + 0.0722 * b;

const adjustLuminance = (hexColor: string): string => {
  const { r, g, b } = hexToRgb(hexColor);
  const luminance = getLuminance({ r, g, b });

  if (luminance >= 80) return rgbToHex({ r, g, b });

  const targetLuminance = Math.min(luminance + 10, 255);
  const ratio = targetLuminance / luminance;
  const adjustValue = (v: number) => Math.min(Math.ceil(v * ratio), 255);
  const newRgb = { r: adjustValue(r), g: adjustValue(g), b: adjustValue(b) };
  const newLuminance = getLuminance(newRgb);

  if (newLuminance < targetLuminance) {
    const incrementValue = (v: number) => Math.min(v + 1, 255);
    const nextRgb = { r: incrementValue(r), g: incrementValue(g), b: incrementValue(b) };
    return adjustLuminance(rgbToHex(nextRgb));
  }

  return adjustLuminance(rgbToHex(newRgb));
};

const getColors = (theme: Theme, color?: ColorProps) => {
  switch (color!) {
    case 'secondary':
      return theme.palette.secondary;
    case 'error':
      return theme.palette.error;
    case 'warning':
      return theme.palette.warning;
    case 'info':
      return theme.palette.info;
    case 'success':
      return theme.palette.success;
    default:
      return theme.palette.primary;
  }
};

export default getColors;
export { generateHexColorFromString, generateColorsFromString };
