import { HubConnectionState } from '@microsoft/signalr';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

import { StoreHookType } from 'types/common';

interface SignalRStoreType {
  state: HubConnectionState;
  lastUpdated?: Date;
  setState: (state: HubConnectionState) => void;
  setLastUpdated: (lastUpdated: Date | undefined) => void;
}

const SignalRVanillaStore = createStore<SignalRStoreType>((set, get) => ({
  state: HubConnectionState.Disconnected,
  lastUpdated: undefined,
  setState: (state) => set({ state }),
  setLastUpdated: (lastUpdated) => set({ lastUpdated })
}));

export const { setState, setLastUpdated } = SignalRVanillaStore.getState();
export const useSignalRState: StoreHookType<SignalRStoreType> = (selector, equalityFn?) =>
  useStore(SignalRVanillaStore, selector, equalityFn);
