import { ReactElement, useCallback, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

const ScrollTop = ({ children }: { children: ReactElement | null }) => {
  const location = useLocation();
  const { pathname } = location;
  const currentPath = useRef('');

  const shouldScroll = useCallback(() => {
    return !(currentPath.current.includes(pathname) || pathname.includes(currentPath.current));
  }, [pathname]);

  useEffect(() => {
    if (shouldScroll()) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    currentPath.current = pathname;
  }, [pathname, shouldScroll]);

  return children || null;
};

export default ScrollTop;
