import update from 'immutability-helper';

import {
  EP_STORES_UPDATE_DELIVEROO,
  EP_STORES_UPDATE_DELIVEROO_SITE,
  EP_STORES_GET_CARI_STORE,
  EP_STORES_UPDATE_CARI_STORE
} from 'modules/endpoints';
import Fetcher from 'modules/service/fetcher';
import { TimeStringToShortTimeFormat } from 'utils/date-formatter';
import { setQueryData } from 'utils/react-query';

import { EP_WAREHOUSES_LIST, EP_WAREHOUSES_UPDATE, EP_WAREHOUSES_GET_BY_ID, EP_STORES_GET_DELIVEROO_SETTINGS } from './consts';
import {
  CariSiteStatus,
  IDeliverooSiteSettings,
  IStoreCariDetails,
  IStoreDeliverooDetails,
  IWarehouseDetails,
  IWarehouseListItem
} from './types';

module Warehouses {
  export const list = async (): Promise<IWarehouseListItem[]> => await listWithCariStatus(false);

  export const listWithCariStatus = async (getCariStoreStatus?: boolean): Promise<IWarehouseListItem[]> =>
    (await Fetcher.Get<{ items: IWarehouseListItem[] }>(EP_WAREHOUSES_LIST, { getCariStoreStatus })).items;

  export const getById = async (id: string): Promise<IWarehouseDetails> => await Fetcher.Get(EP_WAREHOUSES_GET_BY_ID, { id });

  export const getDeliverooSettingsId = async (id: string): Promise<IStoreDeliverooDetails> =>
    await Fetcher.Get(EP_STORES_GET_DELIVEROO_SETTINGS, { id });

  export const edit = async (requestData: IWarehouseDetails): Promise<void> => {
    await Fetcher.Post(EP_WAREHOUSES_UPDATE, requestData);

    setQueryData<IWarehouseDetails>([EP_WAREHOUSES_GET_BY_ID, requestData.id], () => requestData);
    setQueryData<IWarehouseListItem[]>([EP_WAREHOUSES_LIST], (old) => {
      const itemIndex = old.findIndex((warehouse) => warehouse.id.toString() === requestData.id);
      const newItem: IWarehouseListItem = {
        ...old[itemIndex],
        schedule: requestData.serviceDays,
        status: requestData.status,
        etaBufferTime: requestData.etaBufferTime,
        serviceHours: {
          startTime: TimeStringToShortTimeFormat(requestData.serviceHoursStartTime),
          endTime: TimeStringToShortTimeFormat(requestData.serviceHoursEndTime)
        }
      };

      return update(old, { [itemIndex]: { $set: newItem } });
    });
  };

  export const editDeliverooStoreDetails = async (requestData: IStoreDeliverooDetails): Promise<void> => {
    await Fetcher.Post(EP_STORES_UPDATE_DELIVEROO, requestData);

    setQueryData<IStoreDeliverooDetails>([EP_STORES_GET_DELIVEROO_SETTINGS, requestData.id], () => requestData);
    setQueryData<IWarehouseListItem[]>([EP_WAREHOUSES_LIST], (old) => {
      const itemIndex = old.findIndex((warehouse) => warehouse.id.toString() === requestData.id);
      const newItem: IWarehouseListItem = {
        ...old[itemIndex],
        deliverooSiteStatus: requestData.deliverooSiteStatus
      };
      return update(old, { [itemIndex]: { $set: newItem } });
    });
  };

  export const editDeliverooSite = async (requestData: IDeliverooSiteSettings): Promise<void> => {
    await Fetcher.Post(EP_STORES_UPDATE_DELIVEROO_SITE, requestData);

    setQueryData<IStoreDeliverooDetails>([EP_STORES_GET_DELIVEROO_SETTINGS, requestData.id], (old) => ({
      ...old,
      deliverooSiteId: requestData.deliverooSiteId
    }));
  };

  export const getCariStoreDetails = async (id: string): Promise<IStoreCariDetails> => await Fetcher.Get(EP_STORES_GET_CARI_STORE, { id });

  export const editCariStoreDetails = async (requestData: IStoreCariDetails): Promise<void> => {
    await Fetcher.Post(EP_STORES_UPDATE_CARI_STORE, requestData);

    setQueryData<IStoreCariDetails>([EP_STORES_GET_CARI_STORE, requestData.id], () => requestData);
    setQueryData<IWarehouseListItem[]>([EP_WAREHOUSES_LIST], (old) => {
      const itemIndex = old.findIndex((warehouse) => warehouse.id.toString() === requestData.id);
      const newItem: IWarehouseListItem = {
        ...old[itemIndex],
        cariSiteStatus: requestData.status ? CariSiteStatus.Open : CariSiteStatus.Closed
      };
      return update(old, { [itemIndex]: { $set: newItem } });
    });
  };
}

export default Warehouses;
export * from './types';
export * from './consts';
