import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import { ReactElement } from 'react';

import { PERMISSIONS } from './consts';
import { usePermission } from './hooks';

type Props = {
  children: ReactElement;
  permission?: bigint;
  showError?: boolean;
};

export const PermissionGate: React.FC<Props> = ({ permission = PERMISSIONS.NONE, showError, children }) => {
  const hasAccess = usePermission(permission);

  if (hasAccess) return <>{children}</>;

  if (!showError) return null;

  return (
    <Grid container>
      <Grid item xs>
        <Alert severity="error">You don't have permissions to visit this page</Alert>
      </Grid>
    </Grid>
  );
};
