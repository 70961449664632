import { BarChartOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { PERMISSIONS } from 'modules/permission';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const reports: NavItemType = {
  id: 'reports',
  title: <FormattedMessage id="reports" />,
  type: 'group',
  permission: PERMISSIONS.REPORTS.READ,
  children: [
    {
      id: 'cashReport',
      title: <FormattedMessage id="cashReport" />,
      type: 'item',
      url: '/reports/cashReport',
      icon: BarChartOutlined,
      permission: PERMISSIONS.REPORTS.READ
    }
  ]
};
export default reports;
