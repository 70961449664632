import firebase from 'firebase/compat/app';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

import { PERMISSIONS } from 'modules/permission';
import { StoreHookType } from 'types/common';

interface AuthProps {
  user: firebase.User | null;
  permissions: bigint;
}

interface AuthStoreProps extends AuthProps {
  setUser: (user: firebase.User | null) => void;
}

const AuthVanillaStore = createStore<AuthStoreProps>((set, get) => ({
  user: null,
  permissions: PERMISSIONS.NONE,
  setUser: async (user) => {
    const tokenResult = await user?.getIdTokenResult();
    set({ user, permissions: BigInt(tokenResult?.claims.EmployeePermissions ?? 0) });
  }
}));

const { setUser } = AuthVanillaStore.getState();
export const useAuth: StoreHookType<AuthStoreProps> = (selector, equalityFn?) => useStore(AuthVanillaStore, selector, equalityFn);

export const AuthStore = { setUser };
