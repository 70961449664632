import { Box, Typography } from '@mui/material';

import { PermissionGate } from 'modules/permission';
import menuItem from 'routes/menu-items';
import useMenu from 'store/menu';

import NavGroup from './NavGroup';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const drawerOpen = useMenu((state) => state.drawerOpen);

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <PermissionGate permission={item.permission} key={item.id}>
            <NavGroup item={item} />
          </PermissionGate>
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;
