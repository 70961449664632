import firebase from 'firebase/compat/app';

import Snackbar from 'components/@extended/Snackbar';
import { AuthStore } from 'modules/auth';
import { EP_SIGN_IN } from 'modules/endpoints';
import Fetcher from 'modules/service/fetcher';

module Auth {
  export const googleSignIn = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(provider);
    } catch (error: any) {
      Snackbar.show('Google SignIn Failed', 'error');
      throw new Error('Google SignIn Failed');
    }
    await Auth.verifyUser();
  };

  export const verifyUser = async (): Promise<void> => {
    const user = firebase.auth().currentUser;
    if (!user) return;
    try {
      await Fetcher.Post(EP_SIGN_IN);
      await user.getIdToken(true);
      AuthStore.setUser(user);
    } catch (error: any) {
      Auth.logout();
      if (error.toJSON().status === 401) {
        Snackbar.show('User not Authorised to Login', 'error');
        throw new Error();
      }
      Snackbar.show('SignIn Failed', 'error');
      throw new Error('SignIn Failed');
    }
  };

  export const logout = async () => {
    await firebase.auth().signOut();
    AuthStore.setUser(null);
  };
}

export default Auth;
export * from './store';
