import { useMemo } from 'react';

import { useAuth } from 'modules/auth';

import Permissions from './';

export const usePermission = (permission: bigint) => {
  const permissions = useAuth((state) => state.permissions);

  return useMemo(() => Permissions.check(permission, permissions), [permissions, permission]);
};
