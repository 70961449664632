import { Alert } from '@mui/material';
import { Fade, Grow, Slide, SlideProps } from '@mui/material';
import { AlertColor, AlertProps, SnackbarOrigin } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import React from 'react';
import { createElement } from 'react';

import { createStore, useStore } from 'zustand';

// =================================== STORE =================================== //
const initialState: SnackbarProps = {
  open: false,
  message: 'Note archived',
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  alert: { color: 'primary', variant: 'filled' },
  transition: 'Fade'
};

const SnackbarVanillaStore = createStore<SnackbarStoreProps>((set) => ({
  snackbar: initialState,
  openSnackbar: (snackbarInput) => set({ snackbar: { ...initialState, open: true, ...snackbarInput } }),
  closeSnackbar: () => set({ snackbar: initialState })
}));

const { openSnackbar, closeSnackbar } = SnackbarVanillaStore.getState();

// =================================== COMPONENT =================================== //
const TransitionSlide = (direction: SlideProps['direction']) => (props: SlideProps) => createElement(Slide, { ...props, direction });
const GrowTransition = (props: SlideProps) => createElement(Grow, props);

const animation: Record<string, any> = {
  SlideLeft: TransitionSlide('left'),
  SlideUp: TransitionSlide('up'),
  SlideRight: TransitionSlide('right'),
  SlideDown: TransitionSlide('down'),
  Grow: GrowTransition,
  Fade
};

const SnackbarComponent: React.FC = () => {
  const snackbar = useStore(SnackbarVanillaStore, (state) => state.snackbar);
  const { anchorOrigin, alert, message, open, transition } = snackbar;

  const handleClose = (event: any, reason?: string) => reason !== 'clickaway' && closeSnackbar();

  return (
    <MuiSnackbar
      TransitionComponent={animation[transition]}
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} {...alert}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

const Snackbar = SnackbarComponent as ISnackbar;
Snackbar.show = (message, severity) => openSnackbar({ message, alert: { severity, variant: 'filled' } });
Snackbar.showCustom = (message, data) => openSnackbar({ ...data, message });
Snackbar.close = closeSnackbar;

export default Snackbar;

interface SnackbarProps {
  open: boolean;
  message: string;
  anchorOrigin: SnackbarOrigin;
  alert: AlertProps;
  transition: string;
}

interface SnackbarStoreProps {
  snackbar: SnackbarProps;
  openSnackbar: (snackbarData: Partial<SnackbarProps>) => void;
  closeSnackbar: () => void;
}

type ISnackbar = React.FC & {
  show: (message: string, color: AlertColor) => void;
  showCustom: (message: string, data: Partial<SnackbarProps>) => void;
  close: () => void;
};
