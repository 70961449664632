import { useMediaQuery, Box, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import Breadcrumbs from 'components/@extended/Breadcrumbs';
import navigation from 'routes/menu-items';
import useConfig from 'store/config';
import useMenu, { setDrawerOpen } from 'store/menu';

import FallbackRenderer from '../FallbackRenderer';

import Drawer from './Drawer';
import Header from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  const miniDrawer = useConfig((state) => state.miniDrawer);

  const drawerOpen = useMenu((state) => state.drawerOpen);

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    setDrawerOpen(!open);
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      setDrawerOpen(!matchDownLG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  return (
    <ErrorBoundary FallbackComponent={FallbackRenderer}>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          <Toolbar />
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
            <Outlet />
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default MainLayout;
