export const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const scheduleWeekText = (weekList: number[]) => {
  if ([0, 1, 2, 3, 4, 5, 6].every((weekDayNumber) => weekList.includes(weekDayNumber))) {
    return 'Every Day';
  }

  if ([0, 1, 2, 3, 4].every((weekDayNumber) => weekList.includes(weekDayNumber))) {
    return 'Weekdays';
  }

  return weekList.map((week) => weekdays[week]).join(', ');
};

export const kwOffsetHours: number = 3;

export const Time24StringToDateTime = (timeText: string): Date => {
  const timeSplit = timeText.split(':');
  const date = new Date();

  date.setHours(Number(timeSplit[0]));
  date.setMinutes(Number(timeSplit[1]));
  date.setSeconds(0);

  return date;
};

export const Time12StringToDateTime = (timeText: string): Date => {
  var date = new Date();
  const [time, modifier] = timeText.split(' ');
  const [hours, minute] = time.split(':');

  date.setHours(
    parseInt(hours) === 12 && modifier === 'AM'
      ? 0
      : parseInt(hours) === 12 && modifier === 'PM'
      ? 12
      : parseInt(hours) + (modifier === 'AM' ? 0 : 12)
  );
  date.setMinutes(parseInt(minute));
  date.setSeconds(0);

  return date;
};

export const DateTimeToShortTimeFormat = (date: Date): string => {
  const hours = [date.getHours() % 12 || 12];
  const amPm = date?.getHours() >= 12 ? 'PM' : 'AM';

  return `${hours}:${date?.getMinutes().toString().padStart(2, '0')} ${amPm}`;
};

export const TimeStringToShortTimeFormat = (timeText: string): string => DateTimeToShortTimeFormat(Time24StringToDateTime(timeText));

export const DateTimeTo24TimeFormat = (date: Date): string => `${date?.getHours()}:${date?.getMinutes()}${date?.getSeconds()}`;

export const toISODateString = (date: Date) => date.toISOString().split('T')[0];
export const extractDateFromISOString = (date: String) => date.split('T')[0]; // 2024-03-15

export const DateInputFormat = 'yyyy-MM-dd';
